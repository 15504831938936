import { render, staticRenderFns } from "./resultApproval.vue?vue&type=template&id=444296c1&"
import script from "./resultApproval.vue?vue&type=script&lang=js&"
export * from "./resultApproval.vue?vue&type=script&lang=js&"
import style0 from "./resultApproval.vue?vue&type=style&index=0&id=444296c1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports